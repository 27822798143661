export const Geral = {
    // Letter A:
    ABRIR_PROTOCOLO: 'Open protocol',
    ACAO_CONFIRMADA: 'Action confirmed successfully',
    ACAO_ERRO: 'Error executing action',
    ACOES: 'Actions',
    SEM_ACOES: 'No actions',
    ADICIONAR: 'Add',
    ALTERAR_SENHA: 'Change Password',
    AVISO: 'Warning',

    // Letter B:
    BAIXAR: 'Download',
    BTN_VOLTAR_EXPLICACAO: 'Return to previous page',

    // Letter C:
    CADASTRAR: 'Register',
    CONFIRMAR: 'Confirm',
    CONFIRMACAO_SAIR:
        'Are you sure you want to exit? Unsaved changes will be lost',
    CONFIRMAR_NOVA_SENHA: 'Confirm New Password',
    CONFIRMACAO_SENHA: 'Password Confirmation',

    // Letter D:
    // Letter E:
    DIGITE_OU_PROCURE: 'Type or search',
    DIGITE_OU_SELECIONE: 'Type or select',
    EDITAR: 'Edit',
    EMAIL: 'Email',
    EMAIL_ENVIADO: 'Email sent successfully!',
    ERRO: 'Error',
    ESQUECEU_SENHA: 'Forgot your password?',
    EXIBIR: 'Show',
    EXCLUIR: 'Delete',

    // Letter F:
    FECHAR: 'Close',
    FILTRAR: 'Filter',

    // Letter G:
    // Letter H:
    HOME_BEM_VINDO:
        'Welcome to the academic portal of Colégio Evangélico Jaraguá',
    HOME_DESCRICAO:
        'Through the academic portal, you can track academic information such as your partial grades and report cards. Additionally, you can view your contracts and monitor financial data.',
    HOME_APROVEITE:
        'Take advantage of this functionality to follow your academic life from anywhere.',
    // Letter I:
    INGLES: 'English',

    // Letter J:
    // Letter K:
    // Letter L:
    LIMPAR: 'Clear',
    LOGIN: 'Login',
    LOREM_IPSUM:
        'Placeholder text for demonstration purposes. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    LIMPAR_FILTRO: 'Clear filter',
    // Letter M:
    MENSAGEM_CONFIRMAR_ACAO: 'Are you sure you want to perform this action?',

    // Letter N:
    NAO: 'No',
    NENHUM_REGISTRO: 'No records',
    NOME_DO_USUARIO: 'User name',
    NOVA_SENHA: 'New Password',

    // Letter O:
    OK: 'Ok',
    OCULTAR_FILTRO: 'Hide filter',
    OCORREU_UM_ERRO_INTERNO: 'An internal error occurred',

    // Letter P:
    PORTUGUES: 'Portuguese',

    // Letter Q:
    // Letter R:
    REDEFINIR_SENHA: 'Reset password',
    RESPOSTA: 'Response',
    RESPONDIDO: 'Answered',

    // Letter S:
    SAIR: 'Exit',
    SALVAR: 'Save',
    SELECIONE: 'Select',
    SELECIONADO: 'Selected',
    SENHA: 'Password',
    SENHA_ALTERADA: 'Password changed successfully',
    SIM: 'Yes',
    SUCCESSO: 'Success',
    REGISTROS_POR_PAGINA: 'records per page',

    // Letter T:
    TOTAL_REGISTROS: 'Total of {count} records',

    // Letter U:
    // Letter V:
    VISUALIZAR: 'View',
    VOLTAR_LOGIN: 'Back to Login',
    VOLTAR: 'Back',

    // Letter X:
    // Letter Y:
    // Letter Z:
};
