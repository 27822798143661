export const Financeiro = {
    // Letra A:
    ARQUIVO: 'Arquivo',
    ALUNO: 'Aluno',
    ANO_LETIVO: 'Ano letivo',

    // Letra B:
    BAIXAR_CONTRATO: 'Baixar contrato', 
    // Letra C:
    CODIGO: 'Código',
    CONTRATOS: 'Contratos',
    CONTRATO: 'Contrato',
    CONTA_A_RECEBER_ABERTA: 'Em Aberto',
    CONTA_A_RECEBER_PAGO: 'Pago',
    CURSO: 'Curso',
    CONTRATO_VISUALIZAR_DESCRICAO:
        'Nessa tela você pode visualizar os dados do contrato selecionado',

    // Letra D:

    DATA_GERACAO: 'Data de geração',
    DATA_LANCAMENTO: 'Data de lançamento',
    DOWNLOAD_DISPONIVEL: 'Download disponível',
    DOWNLOAD_INDISPONIVEL: 'Download indisponível',
    DESCRICAO: 'Descrição',
    DESCONTO: 'Desconto',
    DADOS_GERAIS: 'Dados gerais', 

    // Letra E:
    // Letra F:
    FINANCEIRO: 'Financeiro',
    FINANCEIRO_DESCRICAO: 'Nessa tela você pode visualizar seus títulos com status de pagamento, também pode baixar a segunda via dos título em aberto.',
    FORMAS_PAGAMENTO: 'Formas de pagamento',

    // Letra G:
    GRUPO_TURMA: 'Grupo turma',
    GERAR_BOLETO: 'Baixar boleto',
    GERAR_RECIBO: 'Baixar recibo',
    // Letra H:
    // Letra I:
    IDIOMA: 'Idioma',
    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    MES: 'Mês',
    MEUS_CONTRATOS: 'Meus contratos',
    MEUS_CONTRATOS_DESCRICAO:
        'Nessa tela você pode visualizar os seus contratos aplicando o filtro de ano letivo, clique em ações nos registros da tabela para baixar ou visualizar o contrato.',
    MATRICULA: 'Matrícula',

    // Letra N:
    // Letra O:
    OBSERVACOES: 'Observações',
    ORIGEM: 'Origem',
    // Letra P:
    PERIODO_INICIAL: 'Data de início', 
    PERIODO_FINAL: 'Data final', 
    PAGAMENTOS: 'Pagamentos',
    PRECOS: 'Preço',

    // Letra Q:
    // Letra R:
    // Letra S:
    SEGUNDA_VIA: 'Segunda via',
    STATUS_PAGAMENTO: 'Status do pagamento',
    STATUS_CONTRATO: 'Status do contrato',
    SEM_PAGAMENTOS: 'Sem pagamentos para o ano selecionado',
    SIMULACAO_PARCELAS: 'Parcelas',
    SEM_DADOS_PARA_LISTAR: 'Sem dados para listar',
    // Letra T:
    TIPO_CONTRATO: 'Tipo de contrato',
    TELA_PAGAMENTOS_DESCRICAO:
        'Essa tela exibe as transações financeiras, com a opção de filtrar a busca. Cada item na tabela possui um botão de ação que permite visualizar e baixar em PDF.',

    // Letra U:
    // Letra V:
    VALOR: 'Valor',
    VENCIMENTO: 'Vencimento',
    VALORES_PRECO_PARCELAMENTO: 'Valores e planos de parcelamento',

    // Letra X:
    // Letra Y:
    // Letra Z:
};
