export const Permissoes = {
  PERFIL: "Profile",
  TELA: "Screen",
  CAMPO: "Field",
  PERMISSOES: "Permissions",
  VISUALIZAR: "View",
  LISTAR: "List",
  CRIAR: "Create",
  EDITAR: "Edit",
  EXCLUIR: "Delete",
};
