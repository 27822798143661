export const Academico = {
    // Letter A:
    ACADEMICO: 'Academic',
    ANO: 'Year',
    ANOS: 'Years',
    ANO_LETIVO: 'School year',
    ATENCAO: 'ATTENTION:',
    ALUNO: 'Student',
    TITULO: 'Title',
    ABRIR_PROTOCOLO: 'Open protocol',
    PROTOCOLO: 'Protocol',
    SOLICITACAO: 'Request',
    SOLICITACAO_SOBRE: 'Enter your request here',
    PROTOCOLO_SOBRE: 'On this page, you can open a protocol to request information changes',
  
    // Letter B:
    BIMESTRE: 'Bimester',
    BOLETIM: 'Report card',
    BOLETIM_SELECIONAR_ANO: 'Select a school year to view the report card',
    BOLETIM_NA0_EXISTE: 'There is no report card record for this year',
    BAIXAR_CONTRATO_ATUAL: 'Download current contract',
    BAIXAR_FICHA_REMATRICULA: 'Download re-enrollment form',
  
    // Letter C:
    CURSO: 'Course',
    COMPETENCIA: 'Competence',
    CONTEUDO: 'Content',
    CONFIRMAR_DADOS_DE_SAUDE:
      'Please confirm the health form data by checking the checkbox at the end of this form. This will grant access to the next form.',
  
    // Letter D:
    DISCIPLINA: 'Subject',
    DESCRICAO_BOLETIM: 'On this screen, you can view your report cards by year',
    DATA_VENCIMENTO: 'Expiration date',
    DATA: 'Date',
    DESCRICAO: 'Description',
    DATA_RECUPERACAO: 'Recovery date',
    DADOS_GERAIS: 'General information',
    DADOS_DE_SAUDE: 'Health information',
    DATA_CRIACAO: 'Creation date',
  
    // Letter E:
    // Letter F:
    FALTAS: 'Absences',
    FORMULARIO_PROTOCOLO: 'Protocol form',
    // Letter G:
    // Letter H:
    HISTORICO: 'History',
    // Letter I:
    IMPRIMIR: 'Print',
  
    // Letter J:
    // Letter K:
    // Letter L:
    // Letter M:
    MEDIA: 'Average',
    MATRICULA: 'Enrollment',
    MATRICULA_REMATRICULA: 'Enrollment/Re-enrollment',
    MATRICULA_VISUALIZAR:
      'On this screen, you can view the enrollment, report enrollment errors, and download the contract.',
    // Letter N:
    NOTAS_PARCIAIS: 'Partial grades',
    NOTA: 'Grade',
    NENHUM_ANO_LETIVO: 'No school year found',
    NENHUMA_DISCIPLINA: 'No discipline found',
    NENHUMA_SEGMENTACAO: 'No bimester found',
    NOTAS_PARCIAIS_DESCRICAO: 'On this screen, you can list partial grades by filter',
  
    // Letter O:
    OBTER_NOTAS_PARCIAIS: 'Get partial grades',
    // Letter P:
    PARECER_DESCRITIVO: 'Descriptive opinion',
    PARECER_DESCRITIVO_DESCRICAO:
      'On this screen, you can view a list of descriptive opinions in a table, and you can download and view each one individually.',
    PARCELA: 'Installment',
  
    // Letter Q:
    // Letter R:
    RECUPERACAO: 'Recovery',
  
    REMATRICULA: 'Online re-enrollment',
    REMATRICULA_DESCRICAO:
      'On this screen, you can view your enrollments and perform re-enrollment.',
    // Letter S:
    STATUS: 'Status',
    SEM_DESCRICAO: 'Description not provided',
    SEN_NOTAS_PARCIAIS: 'No partial grades for the selected filter',
    SITUACAO: 'Situation',
    // Letter T:
    TURMA: 'Class',
    TIPO_AVALIACAO: 'Assessment type',
    TOTAL_FALTAS: 'Total absences',
    TERMOS: 'Terms',
    EXTRA_CLASSE: 'Extracurricular activities',
    EXTRA_CLASSE_INFO:
      'The form below is not a guarantee of a spot, it is a demand survey. Registrations will be held on days and times to be informed in the first week of class. Download the registration form by clicking the button below',
  
    // Letter U:
    // Letter V:
    VISUALIZAR_PARECER: 'View opinion',
    VISUALIZAR_PARECER_DESCRICAO:
      'Here you can view the descriptive opinion and the file if available',
    VISUALIZAR_PARECER_TEXTO_AUSENTE: 'No opinion description to show',
    VISUALIZAR_PARECER_ARQUIVO_AUSENTE:
      'No document record to show',
  
    VALOR: 'Value',
    // Letter X:
    // Letter Y:
    // Letter Z:
  };
  