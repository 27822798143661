export const Financeiro = {
    // Letter A:
    ARQUIVO: 'File',
    ALUNO: 'Student',
    ANO_LETIVO: 'School year',

    // Letter B:
    BAIXAR_CONTRATO: 'Download contract',
    // Letter C:
    CODIGO: 'Code',
    CONTRATOS: 'Contracts',
    CONTRATO: 'Contract',
    CONTA_A_RECEBER_ABERTA: 'Open',
    CONTA_A_RECEBER_PAGO: 'Paid',
    CURSO: 'Course',
    CONTRATO_VISUALIZAR_DESCRICAO:
        'On this screen, you can view the data of the selected contract',

    // Letter D:
    DATA_GERACAO: 'Generation date',
    DATA_LANCAMENTO: 'Release date',
    DOWNLOAD_DISPONIVEL: 'Download available',
    DOWNLOAD_INDISPONIVEL: 'Download unavailable',
    DESCRICAO: 'Description',
    DESCONTO: 'Discount',
    DADOS_GERAIS: 'General information',

    // Letter E:
    // Letter F:
    FINANCEIRO: 'Financial',
    FINANCEIRO_DESCRICAO:
        'On this screen, you can view your titles with payment status, you can also download the second copy of open titles.',
    FORMAS_PAGAMENTO: 'Payment methods',

    // Letter G:
    GRUPO_TURMA: 'Class group',
    GERAR_BOLETO: 'Download invoice',
    GERAR_RECIBO: 'Download receipt',
    // Letter H:
    // Letter I:
    IDIOMA: 'Language',
    // Letter J:
    // Letter K:
    // Letter L:
    // Letter M:
    MES: 'Month',
    MEUS_CONTRATOS: 'My contracts',
    MEUS_CONTRATOS_DESCRICAO:
        'On this screen, you can view your contracts by applying the school year filter, click actions on the table records to download or view the contract.',
    MATRICULA: 'Enrollment',

    // Letter N:
    // Letter O:
    OBSERVACOES: 'Observations',
    ORIGEM: 'Origin',
    // Letter P:
    PERIODO_INICIAL: 'Start date',
    PERIODO_FINAL: 'End date',
    PAGAMENTOS: 'Payments',
    PRECOS: 'Price',

    // Letter Q:
    // Letter R:
    // Letter S:
    SEGUNDA_VIA: 'Second copy',
    STATUS_PAGAMENTO: 'Payment status',
    STATUS_CONTRATO: 'Contract status',
    SEM_PAGAMENTOS: 'No payments for the selected year',
    SIMULACAO_PARCELAS: 'Installments',
    SEM_DADOS_PARA_LISTAR: 'No data to list',
    // Letter T:
    TIPO_CONTRATO: 'Contract type',
    TELA_PAGAMENTOS_DESCRICAO:
        'This screen displays financial transactions, with the option to filter the search. Each item in the table has an action button that allows viewing and downloading in PDF.',

    // Letter U:
    // Letter V:
    VALOR: 'Value',
    VENCIMENTO: 'Due date',
    VALORES_PRECO_PARCELAMENTO: 'Values and installment plans',

    // Letter X:
    // Letter Y:
    // Letter Z:
};
