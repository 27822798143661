export const Menu = {
    ACADEMICO: 'Acadêmico',
    DADOS_ALUNO: 'Dados aluno',
    DASHBOARD: 'Dashboard',
    FICHA_SAUDE: 'Ficha de saúde',
    FINANCEIRO: 'Financeiro',
    FECHAR: 'Fechar',
    LISTA_MATERIAIS: 'Lista de materiais',
    HOME: 'Home',
    MATRICULA: 'Matrícula',
    USUARIOS: 'Usuários',
    PERMISSOES: 'Permissões',
    PERFIS: 'Perfis',
    PROTOCOLO: 'Protocolo',
    MATRICULA_TODAS: 'Todas as matrículas',
    LISTA_DE_MATERIAIS: 'Lista de materiais',
    NOTAS_PARCIAIS: 'Notas parciais',
    PARECER_DESCRITIVO: 'Parecer descritivo',
    BOLETIM: 'Boletim',
    EXTRACLASSES: 'Extraclasses',
    ESPORTE: 'Esporte',
    CULTURA: 'Cultura',
    PROJETOS_ESPECIAIS: 'Projetos especiais',
    VOLUNTARIADO: 'Voluntariado',
    ROBOTICA: 'Robótica',
    PAGAMENTOS: 'Pagamentos',
    CONTRATOS: 'Contratos',
    INICIO: 'Início',
    REMATRICULA_ONLINE: 'Rematrícula online',
    MATRICULA_REMATRICULA: 'Matrícula/Rematrícula'
};
