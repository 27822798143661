export const Matriculas = {
    ACEITO_TERMOS: 'I accept the terms in the contract to proceed with re-enrollment',
    BAIXAR_CONTRATO: 'Download re-enrollment contract',
    BAIXAR_LISTA_MATERIAL: 'Download school supplies list',
    EFETUAR_REMATRICULA: 'Click here to re-enroll',
    FAZER_REMATRICULA: 'Re-enroll',
    MATRICULA_VIGENTE: 'Go to current enrollment',
    MATRICULAS_ANTERIORES: 'View previous enrollments',
    PRAZO: 'Deadline:',
    REMATRICULA: 'Re-enrollment',
    REPORTAR_ERRO: 'Report an error in the enrollment',
    REMATRICULA_AVISO_ABERTURA: 'Attention: re-enrollment is open!',
    VER_TITULOS_ABERTO: 'View open titles',
    VISUALIZAR_CONTRATO: 'View enrollment contract',
    VISUALIZAR_MATRICULA: 'View enrollment',
};
