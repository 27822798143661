export const Menu = {
    ACADEMICO: 'Academic',
    DADOS_ALUNO: 'Student data',
    DASHBOARD: 'Dashboard',
    FICHA_SAUDE: 'Health record',
    FINANCEIRO: 'Financial',
    FECHAR: 'Close',
    LISTA_MATERIAIS: 'Materials list',
    HOME: 'Home',
    MATRICULA: 'Enrollment',
    USUARIOS: 'Users',
    PERMISSOES: 'Permissions',
    PERFIS: 'Profiles',
    PROTOCOLO: 'Protocol',
    MATRICULA_TODAS: 'All enrollments',
    LISTA_DE_MATERIAIS: 'Materials list',
    NOTAS_PARCIAIS: 'Partial grades',
    PARECER_DESCRITIVO: 'Descriptive opinion',
    BOLETIM: 'Report card',
    EXTRACLASSES: 'Extracurricular',
    ESPORTE: 'Sports',
    CULTURA: 'Culture',
    PROJETOS_ESPECIAIS: 'Special projects',
    VOLUNTARIADO: 'Volunteering',
    ROBOTICA: 'Robotics',
    PAGAMENTOS: 'Payments',
    CONTRATOS: 'Contracts',
    INICIO: 'Start',
    REMATRICULA_ONLINE: 'Online re-enrollment',
    MATRICULA_REMATRICULA: 'Enrollment/Re-enrollment'
};
