export const Matriculas = {
    ACEITO_TERMOS: 'Aceito os termos presentes no contrato para efetuar rematrícula',
    BAIXAR_CONTRATO: 'Baixar o contrato da rematrícula',
    BAIXAR_LISTA_MATERIAL: 'Baixar lista de materiais',
    EFETUAR_REMATRICULA: 'Clique aqui para fazer a rematrícula',
    FAZER_REMATRICULA: 'Fazer rematrícula',
    MATRICULA_VIGENTE: 'Ir pra matrícula vigente',
    MATRICULAS_ANTERIORES: 'Visualizar matrículas anteriores',
    PRAZO: 'Prazo:',
    REMATRICULA: 'Rematrícula',
    REPORTAR_ERRO: 'Reportar erro na matrícula',
    REMATRICULA_AVISO_ABERTURA: 'Atenção: rematrícula aberta!',
    VER_TITULOS_ABERTO: 'Ver títulos em aberto',
    VISUALIZAR_CONTRATO: 'Visualizar contrato da matrícula',
    VISUALIZAR_MATRICULA: 'Visualizar matrícula',
}