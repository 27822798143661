import { Academico } from './academico/en'
import { ListaMaterial } from './lista-material/en'
import { FichaSaude } from './ficha-saude/en'
import { Financeiro } from './financeiro/en'
import { Geral } from './geral/en'
import { Matriculas } from './matriculas/en'
import { Menu } from './menu/en'
import { Perfis } from './perfis/en'
import { Permissoes } from './permissoes/en'
import { Usuarios } from './usuarios/en'
import { Validation } from './validation/en'

export const en = {
  ACADEMICO: { ...Academico },
  USUARIOS: { ...Usuarios },
  PERMISSOES: { ...Permissoes },
  PERFIS: { ...Perfis },
  LISTA_MATERIAL: { ...ListaMaterial },
  FICHA_SAUDE: { ...FichaSaude },
  FINANCEIRO: { ...Financeiro },
  MATRICULAS: { ...Matriculas },
  TITULO: 'Lyncas Base Portal',
  GERAL: { ...Geral },
  MENU: { ...Menu },
  CAMPOS_VALIDACAO: { ...Validation },
}
