export const Academico = {
    // Letra A:
    ACADEMICO: 'Acadêmico',
    ANO: 'Ano',
    ANOS: 'Anos',
    ANO_LETIVO: 'Ano letivo',
    ATENCAO: 'ATENÇÃO:',
    ALUNO: 'Aluno(a)',
    RESPOSTA: 'Resposta',
    TITULO: 'Título',
    ABRIR_PROTOCOLO: 'Abrir protocolo',
    PROTOCOLO: 'Protocolo',
    SOLICITACAO: 'Solicitação',
    SOLICITACAO_SOBRE: 'Digite aqui sua solicitação',
    PROTOCOLO_SOBRE: 'Nesta página, você pode abrir protoclo para soliciar alteração em informações',

    // Letra B:
    BIMESTRE: 'Bimestre',
    BOLETIM: 'Boletim',
    BOLETIM_SELECIONAR_ANO: 'Selecione um ano letivo para ver o boletim',
    BOLETIM_NA0_EXISTE: 'Não há registro de boletim para esse ano',
    BAIXAR_CONTRATO_ATUAL: 'Baixar contrato',
    BAIXAR_FICHA_REMATRICULA: 'Baixar ficha de inscrição',

    // Letra C:
    CURSO: 'Curso',
    COMPETENCIA: 'Competência',
    CONTEUDO: 'Conteúdo',
    CONFIRMAR_DADOS_DE_SAUDE:
        'Por favor, confirme os dados do formulário de saúde marcando a caixa de seleção no final deste formulário. Isso permitirá o acesso ao próximo formulário.',

    // Letra D:
    DISCIPLINA: 'Disciplina',
    DESCRICAO_BOLETIM: 'Nessa tela você pode visualizar seus boletins por ano',
    DATA_VENCIMENTO: 'Data de vencimento',
    DATA: 'Data',
    DESCRICAO: 'Descrição',
    DATA_RECUPERACAO: 'Data da recuperação',
    DADOS_GERAIS: 'Informações gerais',
    DADOS_DE_SAUDE: 'Informações de saúde',
    DATA_CRIACAO: 'Dara de criação',

    // Letra E:
    // Letra F:
    FALTAS: 'Faltas',
    FORMULARIO_PROTOCOLO: 'Formulário protocolo',
    // Letra G:
    // Letra H:
    HISTORICO: 'Histórico',
    // Letra I:
    IMPRIMIR: 'Imprimir',

    // Letra J:
    // Letra K:
    // Letra L:
    // Letra M:
    MEDIA: 'Média',
    MATRICULA: 'Matrícula',
    MATRICULA_REMATRICULA: 'Matrícula/Rematrícula',
    MATRICULA_VISUALIZAR:
        'Nessa tela você pode visualizar a matrícula, reportar erros na matrícula e baixar o contrato.',
    // Letra N:
    NOTAS_PARCIAIS: 'Notas parciais',
    NOTA: 'Nota',
    NENHUM_ANO_LETIVO: 'Nenhum ano letivo encontrado',
    NENHUMA_DISCIPLINA: 'Nenhuma disciplina encontrada',
    NENHUMA_SEGMENTACAO: 'Nenhum bimestre encontrado',
    NOTAS_PARCIAIS_DESCRICAO:
        'Nessa tela você pode listar notas parciais por filtro',

    // Letra O:
    OBTER_NOTAS_PARCIAIS: 'Obter notas parciais',
    // Letra P:
    PARECER_DESCRITIVO: 'Parecer descritivo',
    PARECER_DESCRITIVO_DESCRICAO:
        'Nesta tela, você pode visualizar uma lista de pareceres descritivos em uma tabela, e pode baixar e visualizar cada um individualmente.',
    PARCELA: 'Parcela',

    // Letra Q:
    // Letra R:
    RECUPERACAO: 'Recuperação',

    REMATRICULA: 'Rematrícula online',
    REMATRICULA_DESCRICAO:
        'Nessa tela você pode ver suas matrículas e realizar a rematrícula.',
    // Letra S:
    STATUS: 'Status',
    SEM_DESCRICAO: 'Descrição não informada',
    SEN_NOTAS_PARCIAIS: 'Sem notas parciais para o filtro selecionado',
    SITUACAO: 'Situação',
    // Letra T:
    TURMA: 'Turma',
    TIPO_AVALIACAO: 'Tipo avaliação',
    TOTAL_FALTAS: 'Total faltas',
    TERMOS: 'Termos',
    EXTRA_CLASSE: 'Atividades extraclasse',
    EXTRA_CLASSE_INFO:
        'A ficha abaixo não é garantia de vaga, é um levantamento de demanda. As inscrições serão realizadas em dia e horários a serem informados na primeira semana de aula. Baixe a ficha de inscrição clicando no botão abaixo',

    // Letra U:
    // Letra V:
    VISUALIZAR_PARECER: 'Visualizar parecer',
    VISUALIZAR_PARECER_DESCRICAO:
        'Aqui você pode visualizar o parecer descritivo e o arquivo se houver',
    VISUALIZAR_PARECER_TEXTO_AUSENTE: 'Sem descrição do parecer para mostrar',
    VISUALIZAR_PARECER_ARQUIVO_AUSENTE:
        'Sem registro de documento para mostrar',

    VALOR: 'Valor',
    // Letra X:
    // Letra Y:
    // Letra Z:
};
