const USUARIO_ID = "portal_aluno_usuario_id";
const buscarId = () => window.localStorage.getItem(USUARIO_ID);
const salvarId = (id) => window.localStorage.setItem(USUARIO_ID, id);

const USUARIO_TOKEN = "portal_aluno_usuario_token";
const buscarToken = () => window.localStorage.getItem(USUARIO_TOKEN);
const salvarToken = (token) => window.localStorage.setItem(USUARIO_TOKEN, token);
const excluirToken = () => window.localStorage.removeItem(USUARIO_TOKEN);

const USUARIO_NOME = "portal_aluno_usuario_nome";
const buscarNome = () => window.localStorage.getItem(USUARIO_NOME);
const salvarNome = (nome) => window.localStorage.setItem(USUARIO_NOME, nome);
const excluirNome = () => window.localStorage.removeItem(USUARIO_NOME);

const USUARIO_FOTO = "portal_aluno_usuario_foto";
const buscarFoto = () => window.localStorage.getItem(USUARIO_FOTO);
const salvarFoto = (foto) => window.localStorage.setItem(USUARIO_FOTO, foto);
const excluirFoto = () => window.localStorage.removeItem(USUARIO_FOTO);

const USUARIO_SOBRENOME = "portal_aluno_usuario_sobrenome";
const buscarSobrenome = () => window.localStorage.getItem(USUARIO_SOBRENOME);
const salvarSobrenome = (sobrenome) => window.localStorage.setItem(USUARIO_SOBRENOME, sobrenome);
const excluirSobrenome = () => window.localStorage.removeItem(USUARIO_SOBRENOME);

const USUARIO_EMAIL = "portal_aluno_usuario_email";
const buscarEmail = () => window.localStorage.getItem(USUARIO_EMAIL);
const salvarEmail = (email) => window.localStorage.setItem(USUARIO_EMAIL, email);
const excluirEmail = () => window.localStorage.removeItem(USUARIO_EMAIL);

const USUARIO_MATRICULA = "portal_aluno_usuario_matricula";
const buscarMatricula = () => window.localStorage.getItem(USUARIO_MATRICULA);
const salvarMatricula = (matricula) => window.localStorage.setItem(USUARIO_MATRICULA, matricula);
const excluirMatricula = () => window.localStorage.removeItem(USUARIO_MATRICULA);

const USUARIO_ALUNOS_VINCULADOS = "portal_aluno_usuarios_alunos_vinculados";
const buscarAlunosVinculados = () => JSON.parse(window.localStorage.getItem(USUARIO_ALUNOS_VINCULADOS))
const salvarAlunosVinculados = (alunosVinculados) => window.localStorage.setItem(USUARIO_ALUNOS_VINCULADOS, JSON.stringify(alunosVinculados))
const excluirAlunosVinculados = () => window.localStorage.removeItem(USUARIO_ALUNOS_VINCULADOS)

const MENUS = "portal_aluno_menus";
const buscarMenus = () => JSON.parse(window.localStorage.getItem(MENUS))
const salvarMenus = (alunosVinculados) => window.localStorage.setItem(MENUS, JSON.stringify(alunosVinculados))
const excluirMenus = () => window.localStorage.removeItem(MENUS)


export default {
  salvarId,
  buscarId,
  buscarToken,
  salvarToken,
  excluirToken,
  buscarNome,
  salvarNome,
  excluirNome,
  buscarEmail,
  salvarEmail,
  excluirEmail,
  buscarSobrenome,
  salvarSobrenome,
  excluirSobrenome,
  buscarMatricula,
  salvarMatricula,
  excluirMatricula,
  buscarAlunosVinculados,
  salvarAlunosVinculados,
  excluirAlunosVinculados,
  buscarFoto,
  salvarFoto,
  excluirFoto,
  buscarMenus,
  salvarMenus,
  excluirMenus
};
