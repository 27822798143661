export const Usuarios = {
  ID: "ID",
  NOME: "Nome",
  SOBRENOME: "Sobrenome",
  CPF_CNPJ: "CPF / CNPJ",
  RG: "RG",
  EMAIL: "E-mail",
  TELEFONE: "Telefone",
  DATA_NASCIMENTO: "Data de Nascimento",
  ENDERECO: "Endereço",
  CONFIRMAR_EXCLUIR: "Tem certeza que deseja excluir o usuário {nome}?",
  MENSAGEM_EXCLUIDO: "Usuário excluído com sucesso.",
  MENSAGEM_CADASTRADO: "Usuário cadastrado com sucesso",
  DADOS_PESSOAIS: "Dados Pessoais",
  LOGRADOURO: "Logradouro",
  NUMERO: "Número",
  CEP: "Cep",
  COMPLEMENTO: "Complemento",
  BAIRRO: "Bairro",
  CIDADE_NOME: "Cidade",
  ESTADO_NOME: "Estado",
  DADOS_ACESSO: "Dados de Acesso",
  ADICIONAR_PERFIS: "Adicionar perfils",
  PERFIL_ASSOCIADO_SUCESSO: "Perfil associado com sucesso",
};
