import Vue from 'vue';
import Router from 'vue-router';
import verificaAutenticacao from '@/common/middleware/verificaAutenticacao.js';
import redirecionaSeAutenticado from '@/common/middleware/redirecionaSeAutenticado.js';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,

    routes: [
        {
            path: '/',
            redirect: '/dashboard',
            component: () => import('@/layouts/full-layout/FullLayout'),
            children: [
                // dashboard
                {
                    name: 'dashboard',
                    path: '/dashboard',
                    meta: {
                        middleware: verificaAutenticacao,
                        id: 'Dashboard',
                    },
                    component: () => import('@/views/home/Index'),
                },
                // acadêmico
                {
                    path: 'academico',
                    component: () => import('@/layouts/blank-layout/Index'),
                    children: [
                        {
                            name: 'academico',
                            path: '/',
                            component: () => import('@/views/academico/Index'),
                        },
                        {
                            name: 'boletim',
                            path: 'boletim',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Boletim',
                            },
                            component: () =>
                                import('@/views/academico/boletim/Index'),
                        },
                        {
                            name: 'notasParciais',
                            path: 'notas-parciais',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Nota',
                            },
                            component: () =>
                                import(
                                    '@/views/academico/notas-parciais/Index'
                                ),
                        },
                        {
                            name: 'parecerDescritivo',
                            path: 'parecer-descritivo',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'ParecerDescritivo',
                            },
                            component: () =>
                                import(
                                    '@/views/academico/parecer-descritivo/Index'
                                ),
                        },
                        {
                            path: 'protocolo',
                            component: () =>
                                import('@/layouts/blank-layout/Index'),
                            children: [
                                {
                                    name: 'protocolo',
                                    path: '/protocolo',
                                    meta: {
                                        id: 'Protocolo',
                                        middleware: verificaAutenticacao,
                                    },
                                    component: () =>
                                        import(
                                            '@/views/academico/protocolo/Index'
                                        ),
                                },
                            ],
                        },
                        {
                            path: '/matriculas',
                            name: 'matriculas',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Matricula',
                            },
                            component: () =>
                                import('@/views/academico/matriculas/Index'),
                        },
                        {
                            path: 'matricula/:id?',
                            name: 'matricula',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Matricula',
                            },
                            component: () =>
                                import(
                                    '@/views/academico/matriculas/visualizar-matricula/Index.vue'
                                ),
                        },
                        {
                            path: '/rematricula/:id',
                            name: 'rematricula',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Matricula',
                            },
                            component: () =>
                                import(
                                    '@/views/academico/matriculas/rematricula/Index'
                                ),
                        },
                    ],
                },
                // protocolo

                // financeiro
                {
                    path: 'financeiro',
                    component: () => import('@/layouts/blank-layout/Index'),
                    children: [
                        {
                            name: 'pagamentos',
                            path: 'pagamentos',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Titulo',
                            },
                            component: () =>
                                import(
                                    '@/views/financeiro/pagamentos/Index.vue'
                                ),
                        },
                        {
                            name: 'contratos',
                            path: 'contratos',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Contrato',
                            },
                            component: () =>
                                import(
                                    '@/views/financeiro/meus-contratos/Index.vue'
                                ),
                        },
                        {
                            name: 'visualizar-contrato',
                            path: 'contrato/:id',
                            meta: {
                                middleware: verificaAutenticacao,
                                id: 'Contrato',
                            },
                            component: () =>
                                import(
                                    '@/views/financeiro/meus-contratos/contrato/Index.vue'
                                ),
                        },
                    ],
                },
            ],
        },
        {
            path: '/',
            component: () => import('@/layouts/blank-layout/Index'),
            children: [
                {
                    name: 'Login',
                    path: 'login',
                    meta: {
                        middleware: redirecionaSeAutenticado,
                    },
                    component: () => import('@/views/autenticacao/login/Index'),
                },

                {
                    name: 'Cadastro',
                    path: 'cadastro',
                    meta: {
                        middleware: redirecionaSeAutenticado,
                    },
                    component: () => import('@/views/autenticacao/Cadastro'),
                },
                {
                    name: 'esqueceu-sua-senha',
                    path: 'esqueceu-sua-senha/',
                    meta: {
                        middleware: redirecionaSeAutenticado,
                    },
                    component: () =>
                        import('@/views/autenticacao/EsqueceuSenha'),
                },
            ],
        },
        {
            path: '*',
            redirect: '/login',
            //component: () => import("@/views/authentication/Error"),
        },
        {
            path: '/',
            redirect: '/login',
            //component: () => import("@/views/authentication/Error"),
        },
    ],
});

import NProgress from 'nprogress';

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start(800);
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];

    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
