import ApiService from '@/common/api/api.service';

const PessoaService = {
    async buscarPorId(id) {
        let { data } = await ApiService.get(`Pessoa/${id}`);
        return data;
    },
    async getFoto(id) {
        let { data } = await ApiService.get(`Pessoa/recuperar-foto`, {
            params: {
                id,
            },
        });
        return data;
    },
};

export default PessoaService;
