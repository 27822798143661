export default [
    {
        id: 'Home',
        icon: 'home',
        title: 'Home',
        i18n: 'MENU.INICIO',
        to: '/',
    },
    {
        id: 'academico',
        icon: 'book',
        title: 'Acadêmico',
        i18n: 'MENU.ACADEMICO',
        children: [
            {
                id: 'NotasParciais',
                idRota: 'Nota',
                icon: 'circle',
                title: 'Notas parciais',
                i18n: 'MENU.NOTAS_PARCIAIS',
                to: '/academico/notas-parciais',
            },
            {
                id: 'ParecerDescritivo',
                idRota: 'ParecerDescritivo',
                icon: 'circle',
                title: 'Parecer descritivo',
                i18n: 'MENU.PARECER_DESCRITIVO',
                to: '/academico/parecer-descritivo',
            },
            {
                id: 'Boletim',
                idRota: 'Boletim',
                icon: 'circle',
                title: 'Boletim',
                i18n: 'MENU.BOLETIM',
                to: '/academico/boletim',
            },
            {
                id: 'Rematricula',
                idRota: 'Matricula',
                icon: 'circle',
                title: 'Matrículas',
                i18n: 'MENU.MATRICULA_REMATRICULA',
                to: '/matriculas',
            },
            {
                id: 'ListaDeMateriais',
                idRota: 'Matricula',
                icon: 'circle',
                title: 'Lista de materiais',
                i18n: 'MENU.LISTA_DE_MATERIAIS',
                isExternalLink: true,
                to: 'https://colegiojaragua.com.br/informacoes/lista-de-materiais',
            },
            {
                id: 'protocolo',
                icon: 'circle',
                idRota: 'Protocolo',
                title: 'Protocolos',
                i18n: 'MENU.PROTOCOLO',
                to: '/protocolo',
            },
        ],
    },
    {
        id: 'extraclasses',
        icon: 'globe',
        title: 'Extraclasses',
        i18n: 'MENU.EXTRACLASSES',
        to: '/extraclasses',
        children: [
            {
                id: 'Esportes',
                icon: 'circle',
                idRota: 'Matricula',
                title: 'Esporte',
                i18n: 'MENU.ESPORTE',
                isExternalLink: true,
                to: 'https://colegiojaragua.com.br/extraclasse/esporte',
            },
            {
                id: 'Cultura',
                idRota: 'Matricula',
                icon: 'circle',
                title: 'Cultura',
                i18n: 'MENU.CULTURA',
                isExternalLink: true,
                to: 'https://colegiojaragua.com.br/extraclasse/cultura',
            },
        ],
    },
    {
        id: 'projetosEspeciais',
        icon: 'star',
        title: 'Projetos especiais',
        i18n: 'MENU.PROJETOS_ESPECIAIS',
        to: '/projetos-especiais',
        children: [
            {
                id: 'voluntariado',
                idRota: 'Matricula',
                icon: 'circle',
                title: 'Voluntariado',
                i18n: 'MENU.VOLUNTARIADO',
                isExternalLink: true,
                to: 'https://colegiojaragua.com.br/projetos-especiais/voluntariado',
            },
            {
                id: 'robotica',
                idRota: 'Matricula',
                icon: 'circle',
                title: 'Robótica',
                i18n: 'MENU.ROBOTICA',
                isExternalLink: true,
                to: 'https://colegiojaragua.com.br/projetos-especiais/robotica',
            },
        ],
    },
    {
        id: 'financiro',
        icon: 'dollar-sign',
        title: 'Financeiro',
        i18n: 'MENU.FINANCEIRO',
        children: [
            {
                id: 'Contratos',
                icon: 'circle',
                idRota: 'Contrato',
                title: 'Contratos',
                i18n: 'MENU.CONTRATOS',
                to: '/financeiro/contratos',
            },
            {
                id: 'Pagamentos',
                idRota: 'Titulo',
                icon: 'circle',
                title: 'Pagamentos',
                i18n: 'MENU.PAGAMENTOS',
                to: '/financeiro/pagamentos',
            },
        ],
    },
];
