export const Usuarios = {
  ID: "ID",
  NOME: "Name",
  SOBRENOME: "Last Name",
  CPF_CNPJ: "CPF / CNPJ",
  RG: "ID Document",
  EMAIL: "Email",
  TELEFONE: "Phone",
  DATA_NASCIMENTO: "Date of Birth",
  ENDERECO: "Address",
  CONFIRMAR_EXCLUIR: "Are you sure you want to delete user {nome}?",
  MENSAGEM_EXCLUIDO: "User deleted successfully.",
  MENSAGEM_CADASTRADO: "User registered successfully",
  DADOS_PESSOAIS: "Personal Information",
  LOGRADOURO: "Street",
  NUMERO: "Number",
  CEP: "Zip Code",
  COMPLEMENTO: "Complement",
  BAIRRO: "Neighborhood",
  CIDADE_NOME: "City",
  ESTADO_NOME: "State",
  DADOS_ACESSO: "Access Data",
  ADICIONAR_PERFIS: "Add Profiles",
  PERFIL_ASSOCIADO_SUCESSO: "Profile associated successfully",
};
