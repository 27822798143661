import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import VueTheMask from "vue-the-mask";
import moment from "moment-timezone";

// i18n
import i18n from "./i18n/i18n.js";
import "vue-hljs/dist/style.css";
Vue.use(VueFeather);

moment.tz.setDefault("America/Belem");

import vueHljs from "vue-hljs";
import hljs from "highlight.js";

//use
Vue.use(vueHljs, { hljs });
Vue.use(InstantSearch);
Vue.use(VueTheMask);
new Vue({ store, router, i18n, render: (h) => h(App) }).$mount("#app");

String.prototype.formatUnicorn =
    String.prototype.formatUnicorn ||
    function () {
        "use strict";
        var str = this.toString();
        if (arguments.length) {
            var t = typeof arguments[0];
            var key;
            var args =
                "string" === t || "number" === t
                    ? Array.prototype.slice.call(arguments)
                    : arguments[0];

            for (key in args) {
                str = str.replace(
                    new RegExp("\\{" + key + "\\}", "gi"),
                    args[key]
                );
            }
        }

        return str;
    };

// TODO: comentado enquanto está dando erro ฅ⁠^⁠•⁠ﻌ⁠•⁠^⁠ฅ - GÊ, copiei a solução da Bea do outro front e resolveu o problema dos ícones hihi
// fontawesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Adicionando ícones a biblioteca:
import {
    faArrowsRotate,
    faAddressBook,
    faCircleQuestion,
    faCircleLeft,
    faEye,
    faEyeSlash,
    faFilePen,
    faFilter,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

// Adicionando ícones a biblioteca:
library.add(
    faArrowsRotate,
    faAddressBook,
    faCircleQuestion,
    faCircleLeft,
    faEye,
    faEyeSlash,
    faFilter,
    faFilePen,
    faTrash
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
