export const Geral = {
    // Letra A:
    ABRIR_PROTOCOLO: 'Abrir protocolo',
    ACAO_CONFIRMADA: 'Ação confirmada com sucesso',
    ACAO_ERRO: 'Erro ao executar ação',
    ACOES: 'Ações',
    SEM_ACOES: 'Sem ações',
    ADICIONAR: 'Adicionar',
    ALTERAR_SENHA: 'Alterar Senha',
    AVISO: 'Aviso',

    // Letra B:
    BAIXAR: 'Baixar',
    BTN_VOLTAR_EXPLICACAO: 'Retornar a página anterior',

    // Letra C:
    CADASTRAR: 'Cadastrar',
    CONFIRMAR: 'Confirmar',
    CONFIRMACAO_SAIR:
        'Tem certeza que deseja sair? As alterações não salvas serão perdidas',
    CONFIRMAR_NOVA_SENHA: 'Confirmar Nova Senha',
    CONFIRMACAO_SENHA: 'Confirmação de Senha',

    // Letra D:
    // Letra E:
    DIGITE_OU_PROCURE: 'Digite ou procure',
    DIGITE_OU_SELECIONE: 'Digite ou selecione',
    EDITAR: 'Editar',
    EMAIL: 'E-mail',
    EMAIL_ENVIADO: 'E-mail enviado com sucesso!',
    ERRO: 'Erro',
    ESQUECEU_SENHA: 'Esqueceu sua senha?',
    EXIBIR: 'Exibir',
    EXCLUIR: 'Excluir',

    // Letra F:
    FECHAR: 'Fechar',
    FILTRAR: 'Filtrar',

    // Letra G:
    // Letra H:
    HOME_BEM_VINDO:
        'Bem vindo ao portal acadêmico do Colégio Evangélico Jaraguá',
    HOME_DESCRICAO:
        'Através do portal acadêmico você pode acompanhar informações acadêmicas de suas notas parciais e boletim. Além disso, pode visualizar os seus contratos, bem como acompanhar os dados financeiros.',
    HOME_APROVEITE:
        'Aproveite esta funcionalidade para acompanhar sua vida acadêmica de qualquer lugar.',
    // Letra I:
    INGLES: 'English',

    // Letra J:
    // Letra K:
    // Letra L:
    LIMPAR: 'Limpar',
    LOGIN: 'Login',
    LOREM_IPSUM:
        'Ut ut lorem velit. Integer mauris arcu, eleifend ut sapien nec, elementum placerat erat. Phasellus varius, mi non finibus ultrices, erat nunc luctus ex, vitae aliquet massa enim id magna.',
    LIMPAR_FILTRO: 'Limpar filtro',
    // Letra M:
    MENSAGEM_CONFIRMAR_ACAO: 'Tem certeza que deseja executar esta ação?',

    // Letra N:
    NAO: 'Não',
    NENHUM_REGISTRO: 'Nenhum registro',
    NOME_DO_USUARIO: 'Nome do usuário',
    NOVA_SENHA: 'Nova Senha',

    // Letra O:
    OK: 'Ok',
    OCULTAR_FILTRO: 'Ocultar filtro',
    OCORREU_UM_ERRO_INTERNO: 'Ocorreu um erro interno',

    // Letra P:
    PORTUGUES: 'Português',

    // Letra Q:
    // Letra R:
    REDEFINIR_SENHA: 'Redefinir senha',
    RESPOSTA: 'Resposta',
    RESPONDIDO: 'Respondido',

    // Letra S:
    SAIR: 'Sair',
    SALVAR: 'Salvar',
    SELECIONE: 'Selecione',
    SELECIONADO: 'Selecionado',
    SENHA: 'Senha',
    SENHA_ALTERADA: 'Senha Alterada com Sucesso',
    SIM: 'Sim',
    SUCCESSO: 'Sucesso',
    REGISTROS_POR_PAGINA: 'registros por página',

    // Letra T:
    TOTAL_REGISTROS: 'Total de {count} registros',

    // Letra U:
    // Letra V:
    VISUALIZAR: 'Visualizar',
    VOLTAR_LOGIN: 'Voltar ao Login',
    VOLTAR: 'Voltar',

    // Letra X:
    // Letra Y:
    // Letra Z:
};
