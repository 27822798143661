import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Jwt from "@/common/utils/jwt";
import { API_URL } from "@/config/config";

axios.interceptors.request.use((config) => {
  const indexInterrogacao = config.url.indexOf("?");
  const indexUltimaBarra = config.url.lastIndexOf("/");
  if (indexInterrogacao < indexUltimaBarra && indexInterrogacao >= 0) {
    config.url = config.url.substring(0, config.url.length - 1);
  }
  return config;
});
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    this.setbaseURL(API_URL);
  },
  setbaseURL(url) {
    Vue.axios.defaults.baseURL = url;
  },
  getbaseURL() {
    this.init();
    return Vue.axios.defaults.baseURL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    this.init();
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${Jwt.buscarToken()}`;
    this.setbaseURL(API_URL);
    Vue.axios.defaults.headers.common["Content-Language"] = "pt-BR";
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, params) {
    this.setHeader();
    return Vue.axios.get(`${resource}`, params);
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config = null) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(resource);
  },
  uploadFile(resource, file) {
    let data = new FormData();
    data.append("fileName", file.name);
    data.append("file", file);
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };

    return this.post(resource, data, config);
  },
};

export default ApiService;
