export const TODAS_MATRICULAS = "buscarMatriculas";
export const VISUALIZAR_MATRICULA = "visualizarMatricula";

export default {
  state: {
    matricula: [],
    matriculas: [],
  },
  getters: {
    matricula(state){ return state.matricula; },
    matriculas(state) { return state.matriculas; },
  },
  actions: {
    [TODAS_MATRICULAS](state, payload) { state.commit(TODAS_MATRICULAS, payload); },
    [VISUALIZAR_MATRICULA](state, payload){ state.commit(VISUALIZAR_MATRICULA, payload) }
  },
  mutations: {
    [TODAS_MATRICULAS](state, payload) { state.matriculas = payload; },
    [VISUALIZAR_MATRICULA](state, payload) { state.matricula = payload; },
  },
};
