export const Validation = {
    INVALIDO: "O campo {name} é inválido",
    REQUERIDO: "Por favor informe o campo {name}",
    TAMANHO: "O campo {name} precisa possuir {length} caracteres",
    TAMANHO_MINIMO:
      "O campo {name} precisa possuir no mínimo {min} caracteres",
    TERMOS_CONDICOES: "É necessário aceitar os termos e condições",
    NAO_ENCONTRADO: "O usuário {name} não foi encontrado",
    LOGIN_INVALIDO: "Login inválido",
    CAMPO_REQUERIDO: "Campo requerido",
    COMPIMENTO_MINIMO: "Comprimento mínimo do campo de {length} caracteres",
    COMPRIMENTO_MAXIMO: "Comprimento máximo do campo de {length} caracteres",
    VALOR_MINIMO: "Valor mínimo do campo de é {min}",
    VALOR_MAXIMO: "Valor máximo do campo de é {max}",
    CAMPO_INVALIDO: "Campo não é válido",
    SENHA_E_CONFIRMACAO: "As senhas digitadas não são iguais",
}