import ApiService from "@/common/api/api.service";

const AutenticacaoService = {
  logar(form) {
    return ApiService.post(`Autenticacao/autenticar-aluno`, {
      ...form,
    });
  },
  validarToken(token){
    return ApiService.get(`Autenticacao/token-valido?token=${token}`);
  },
  invalidarToken(){
    return ApiService.put(`Autenticacao/invalidar-token`);
  },
};

export default AutenticacaoService;
