export const TODAS_PESSOAS = 'todasPessoas';

export default {
    state: {
        todasPessoas: []
    },
    getters: {
        todasPessoas(state){
            return state.todasPessoas
        }
    },
    actions: {
        [TODAS_PESSOAS](state, payload){
            state.commit(TODAS_PESSOAS, payload);
        }
    },
    mutations: {
        [TODAS_PESSOAS](state,payload){
            state.todasPessoas = payload
        }
    }
}