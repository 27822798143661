import { Academico } from './academico/pt-br'
import { ListaMaterial } from './lista-material/pt-br'
import { Geral } from './geral/pt-br'
import { FichaSaude } from './ficha-saude/pt-br'
import { Financeiro } from './financeiro/pt-br'
import { Matriculas } from './matriculas/pt-br'
import { Menu } from './menu/pt-br'
import { Perfis } from './perfis/pt-br'
import { Permissoes } from './permissoes/pt-br'
import { Usuarios } from './usuarios/pt-br'
import { Validation } from './validation/pt-br'

export const br = {
  ACADEMICO: { ...Academico },
  USUARIOS: { ...Usuarios },
  PERMISSOES: { ...Permissoes },
  PERFIS: { ...Perfis },
  LISTA_MATERIAL: { ...ListaMaterial },
  FICHA_SAUDE: { ...FichaSaude },
  FINANCEIRO: { ...Financeiro },
  MATRICULAS: { ...Matriculas },
  TITULO: 'Portal Base Lyncas',
  GERAL: { ...Geral },
  MENU: { ...Menu },
  CAMPOS_VALIDACAO: { ...Validation },
}
