export const Validation = {
    INVALIDO: 'The field {name} is invalid',
    REQUERIDO: 'Please provide the {name} field',
    TAMANHO: 'The field {name} must be {length} characters long',
    TAMANHO_MINIMO:
        'The field {name} must have a minimum length of {min} characters',
    TERMOS_CONDICOES: 'You must accept the terms and conditions',
    NAO_ENCONTRADO: 'User {name} not found',
    LOGIN_INVALIDO: 'Invalid login',
    CAMPO_REQUERIDO: 'Required field',
    COMPIMENTO_MINIMO: 'Minimum field length of {length} characters',
    COMPRIMENTO_MAXIMO: 'Maximum field length of {length} characters',
    VALOR_MINIMO: 'Minimum field value is {min}',
    VALOR_MAXIMO: 'Maximum field value is {max}',
    CAMPO_INVALIDO: 'Field is invalid',
    SENHA_E_CONFIRMACAO: 'The entered passwords do not match',
};
